.feature-card2-feature-card {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.feature-card2-icon1 {
  fill: var(--dl-color-gray-500);
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  margin-right: var(--dl-space-space-oneandhalfunits);
}
.feature-card2-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.feature-card2-text1 {
  color: var(--dl-color-gray-white);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.feature-card2-text2 {
  color: var(--dl-color-gray-white);
}
.feature-card2root-class-name {
  width: auto;
}
.feature-card2root-class-name1 {
  width: auto;
}
.feature-card2root-class-name2 {
  width: auto;
}
.feature-card2root-class-name3 {
  width: auto;
}
@media(max-width: 767px) {
  .feature-card2-feature-card {
    flex-direction: row;
  }
  .feature-card2-container {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .feature-card2-container {
    flex-direction: column;
  }
}
