.team-card-testimonial-card {
  display: flex;
  padding: 0px;
  max-width: var(--dl-size-size-maxwidth);
  background: #fff;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.team-card-testimonial {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 144px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(217, 217, 217, 0);
}
.team-card-image {
  top: -4px;
  left: 0px;
  right: 0px;
  width: var(--dl-size-size-xxlarge);
  height: var(--dl-size-size-xxlarge);
  margin: auto;
  position: absolute;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}
.team-card-container1 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 144px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: 144px;
  justify-content: space-between;
  background-color: #262832;
}
.team-card-text1 {
  color: var(--dl-color-gray-white);
  width: 100%;
  font-size: 2.5rem;
  font-style: normal;
  margin-top: var(--dl-space-space-threeunits);
  text-align: center;
  font-weight: 600;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-threeunits);
  text-transform: uppercase;
}
.team-card-text2 {
  color: var(--dl-color-primary-700);
  width: 100%;
  font-size: 2rem;
  text-align: center;
  font-weight: 600;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: var(--dl-space-space-fourunits);
  margin-bottom: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-fourunits);
  text-transform: uppercase;
}
.team-card-text3 {
  color: var(--dl-color-gray-white);
  width: 100%;
  font-size: 1.5rem;
  margin-top: var(--dl-space-space-threeunits);
  text-align: center;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: var(--dl-space-space-fourunits);
  margin-bottom: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-fourunits);
}
.team-card-container2 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.team-card-icon1 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.team-card-link {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
}
.team-cardroot-class-name {
  width: 40%;
  height: 100%;
}
.team-cardroot-class-name1 {
  background-color: rgba(217, 217, 217, 0);
}
.team-cardroot-class-name2 {
  width: 40%;
  height: 100%;
}
.team-cardroot-class-name3 {
  background-color: rgba(217, 217, 217, 0);
}
.team-cardroot-class-name4 {
  background-color: rgba(217, 217, 217, 0);
}
@media(max-width: 991px) {
  .team-card-text2 {
    align-self: center;
  }
}
@media(max-width: 767px) {
  .team-card-testimonial-card {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .team-cardroot-class-name {
    width: 100%;
  }
  .team-cardroot-class-name2 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .team-card-testimonial-card {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .team-card-text1 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .team-card-text2 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .team-card-text3 {
    margin-bottom: var(--dl-space-space-twounits);
  }
}
