.blog-container10 {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #262832;
}
.blog-hero2 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  min-height: 50vh;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-start;
  background-color: #D9D9D9;
  background-image: url("/bg21-1500h.png");
  background-repeat: no-repeat;
  scroll-snap-align: start;
  background-position: top left;
}
.blog-navbar-interactive {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: 196px;
  padding-right: 196px;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: space-between;
}
.blog-navlink1 {
  display: contents;
}
.blog-image1 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  text-decoration: none;
}
.blog-desktop-menu {
  flex: 1;
  display: flex;
  margin-left: var(--dl-space-space-threeunits);
  justify-content: flex-start;
}
.blog-links1 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.blog-text10 {
  color: #f3f9f9;
  font-style: normal;
  font-family: Exo 2;
  font-weight: 600;
}
.blog-link1 {
  color: #f3f9f9;
  font-style: normal;
  font-family: Exo 2;
  font-weight: 600;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.blog-link2 {
  color: #f3f9f9;
  font-style: normal;
  font-family: Exo 2;
  font-weight: 600;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.blog-text11 {
  color: #f3f9f9;
  font-style: normal;
  font-family: Exo 2;
  font-weight: 600;
  margin-left: var(--dl-space-space-twounits);
}
.blog-navlink2 {
  color: #f3f9f9;
  font-style: normal;
  font-family: Exo 2;
  font-weight: 600;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.blog-burger-menu {
  display: none;
}
.blog-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.blog-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #262832;
}
.blog-nav1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.blog-top {
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.blog-logo {
  width: 5%;
  height: auto;
  margin: var(--dl-space-space-unit);
}
.blog-close-menu {
  width: 5%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blog-icon12 {
  fill: #D9D9D9;
  width: 100%;
  height: 100%;
}
.blog-links2 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.blog-text12 {
  color: #f3f9f9;
  font-size: 3.5rem;
  font-style: normal;
  font-family: Exo 2;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.blog-link3 {
  color: #f3f9f9;
  font-size: 3.5rem;
  font-style: normal;
  font-family: Exo 2;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.blog-link4 {
  color: #f3f9f9;
  font-size: 3.5rem;
  font-style: normal;
  font-family: Exo 2;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.blog-text13 {
  color: #f3f9f9;
  font-size: 3.5rem;
  font-style: normal;
  font-family: Exo 2;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.blog-navlink3 {
  color: #f3f9f9;
  font-size: 3.5rem;
  font-style: normal;
  font-family: Exo 2;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.blog-image2 {
  width: auto;
  height: 15%;
  align-self: center;
  object-fit: cover;
}
.blog-icon-group1 {
  height: 5%;
}
.blog-icon14 {
  fill: #D9D9D9;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-right: var(--dl-space-space-twounits);
}
.blog-icon16 {
  fill: #D9D9D9;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-right: var(--dl-space-space-twounits);
}
.blog-icon18 {
  fill: #D9D9D9;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}
.blog-container11 {
  width: 50%;
  display: flex;
  margin-top: 0px;
  align-items: flex-start;
  margin-left: 196px;
  margin-bottom: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: flex-start;
}
.blog-text14 {
  color: #f3f9f9;
  width: 100%;
  font-size: 36px;
  align-self: flex-start;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  text-align: left;
  font-family: Exo 2;
  font-weight: 700;
  margin-left: 0px;
  text-transform: uppercase;
}
.blog-image3 {
  width: var(--dl-size-size-xxlarge);
  margin-top: var(--dl-space-space-twounits);
  object-fit: cover;
  margin-left: 0px;
}
.blog-image4 {
  right: 0px;
  width: auto;
  bottom: 0px;
  height: 80%;
  position: absolute;
  align-self: flex-end;
  object-fit: cover;
}
.blog-pricing1 {
  width: 100%;
  height: 982px;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #dee5ef;
}
.blog-image5 {
  top: 0px;
  right: 0px;
  width: auto;
  height: auto;
  position: absolute;
  align-self: flex-end;
  object-fit: cover;
}
.blog-container12 {
  flex: 0 0 auto;
  width: 40%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-sixunits);
  align-items: flex-start;
  border-color: rgba(0, 0, 0, 0.2);
  border-width: 1px;
  flex-direction: column;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 2px;
  border-bottom-width: 0px;
}
.blog-text15 {
  color: rgb(117, 179, 181);
  font-size: 1.5em;
}
.blog-text16 {
  color: rgb(33, 50, 62);
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-twounits);
}
.blog-text17 {
  color: rgb(4, 17, 29);
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blog-text19 {
  color: #04111d;
}
.blog-text20 {
  color: #04111d;
}
.blog-text22 {
  font-style: normal;
  font-weight: 700;
}
.blog-text26 {
  color: #04111d;
}
.blog-text29 {
  font-style: normal;
  font-weight: 700;
}
.blog-text30 {
  color: #04111d;
  font-style: normal;
  font-weight: 700;
}
.blog-text33 {
  color: #04111d;
}
.blog-text36 {
  font-style: normal;
  font-weight: 700;
}
.blog-text37 {
  color: #04111d;
  font-style: normal;
  font-weight: 700;
}
.blog-text40 {
  color: #04111d;
}
.blog-text43 {
  font-style: normal;
  font-weight: 700;
}
.blog-text44 {
  color: #04111d;
  font-style: normal;
  font-weight: 700;
}
.blog-text47 {
  color: #04111d;
}
.blog-text49 {
  color: #04111d;
}
.blog-text52 {
  color: #04111d;
}
.blog-text55 {
  color: #04111d;
}
.blog-text57 {
  color: #04111d;
}
.blog-text60 {
  color: #04111d;
}
.blog-text63 {
  color: #04111d;
}
.blog-text65 {
  color: #04111d;
}
.blog-text68 {
  color: #04111d;
}
.blog-text71 {
  color: #04111d;
}
.blog-text73 {
  color: #04111d;
}
.blog-text76 {
  color: #04111d;
}
.blog-button {
  color: var(--dl-color-gray-white);
  padding: 1rem;
  margin-top: var(--dl-space-space-twounits);
  border-width: 0px;
  border-radius: 12px;
  background-color: #42b4bb;
}
.blog-blog {
  width: auto;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  align-items: center;
  justify-content: space-between;
  background-color: #dee5ef;
}
.blog-container13 {
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-color: #203a47;
}
.blog-container14 {
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-color: #203a47;
}
.blog-pricing2 {
  width: 100%;
  height: 982px;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #dee5ef;
}
.blog-container15 {
  flex: 0 0 auto;
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  background-size: cover;
  justify-content: flex-start;
  background-image: url("/bg21-1500h.png");
}
.blog-lottie-node {
  width: 506px;
  height: 636px;
  display: none;
}
.blog-container16 {
  top: 0px;
  flex: 0 0 auto;
  right: 10%;
  width: 30%;
  height: 45%;
  margin: 5%;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  position: absolute;
  box-shadow: -15px 35px 70px 20px rgba(0, 0, 0, 0.43);
  align-items: center;
  border-radius: 16px;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url("/card5-500h.png");
  background-repeat: no-repeat;
  background-position: center;
}
.blog-heading1 {
  color: #86a7b4;
  font-size: 5em;
}
.blog-text79 {
  color: var(--dl-color-gray-white);
  font-size: 2.5em;
}
.blog-container17 {
  flex: 0 0 auto;
  left: 0px;
  width: 30%;
  bottom: 0px;
  height: 45%;
  margin: 5%;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  position: absolute;
  box-shadow: -15px 35px 70px 20px rgba(0, 0, 0, 0.43);
  align-items: center;
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
  background-color: #fefefe;
}
.blog-heading2 {
  color: #4c8683;
  font-size: 5em;
}
.blog-text80 {
  color: var(--dl-color-gray-black);
  font-size: 2.5em;
}
.blog-footer {
  width: 100%;
  display: flex;
  max-width: auto;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  background-size: cover;
  justify-content: space-between;
  background-image: url("/bg21-1500h.png");
}
.blog-container18 {
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.blog-image6 {
  height: 2rem;
  margin-bottom: var(--dl-space-space-twounits);
}
.blog-nav2 {
  flex: 0 0 auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: row;
}
.blog-text81 {
  color: var(--dl-color-gray-white);
  margin-left: 0px;
}
.blog-text82 {
  color: var(--dl-color-gray-white);
  margin-left: var(--dl-space-space-unit);
}
.blog-text83 {
  color: var(--dl-color-gray-white);
  margin-left: var(--dl-space-space-unit);
}
.blog-text84 {
  color: var(--dl-color-gray-white);
  margin-left: var(--dl-space-space-unit);
}
.blog-text85 {
  color: var(--dl-color-gray-white);
  margin-left: var(--dl-space-space-unit);
}
.blog-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.blog-container19 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.blog-text86 {
  color: var(--dl-color-gray-white);
}
.blog-icon-group2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.blog-icon20 {
  fill: #D9D9D9;
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.blog-icon22 {
  fill: #D9D9D9;
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.blog-icon24 {
  fill: #D9D9D9;
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
@media(max-width: 991px) {
  .blog-text14 {
    margin-left: var(--dl-space-space-threeunits);
  }
  .blog-image3 {
    margin-left: var(--dl-space-space-threeunits);
  }
  .blog-blog {
    flex-direction: column;
  }
  .blog-container13 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .blog-container14 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .blog-hero2 {
    height: 1240px;
    align-items: center;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .blog-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .blog-desktop-menu {
    display: none;
  }
  .blog-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .blog-text12 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .blog-link3 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .blog-link4 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .blog-text13 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .blog-navlink3 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .blog-blog {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .blog-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .blog-image6 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .blog-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .blog-container19 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .blog-text86 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .blog-hero2 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .blog-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .blog-mobile-menu {
    padding: 16px;
  }
  .blog-blog {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .blog-footer {
    padding: var(--dl-space-space-unit);
  }
  .blog-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .blog-container19 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .blog-text86 {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
