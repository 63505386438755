.about-container1 {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.about-banner {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  background-size: cover;
  justify-content: space-between;
  background-image: url("/bg1-1500w.png");
}
.about-navbar-interactive {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sixunits);
  padding-right: var(--dl-space-space-sixunits);
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: space-between;
}
.about-navlink1 {
  display: contents;
}
.about-image1 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  text-decoration: none;
}
.about-desktop-menu {
  flex: 1;
  display: flex;
  margin-left: var(--dl-space-space-threeunits);
  justify-content: flex-start;
}
.about-links1 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.about-link10 {
  color: #1266bf;
  font-style: normal;
  font-family: Exo 2;
  font-weight: 600;
  text-decoration: none;
}
.about-link11 {
  color: #1266bf;
  font-style: normal;
  font-family: Exo 2;
  font-weight: 600;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.about-link12 {
  color: #1266bf;
  font-style: normal;
  font-family: Exo 2;
  font-weight: 600;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.about-text10 {
  color: #1266bf;
  display: none;
  font-style: normal;
  font-family: Exo 2;
  font-weight: 600;
  margin-left: var(--dl-space-space-twounits);
}
.about-navlink2 {
  color: #1266bf;
  display: none;
  font-style: normal;
  font-family: Exo 2;
  font-weight: 600;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.about-burger-menu {
  display: none;
}
.about-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.about-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #262832;
}
.about-nav1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.about-top {
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.about-logo {
  width: 5%;
  height: auto;
  margin: var(--dl-space-space-unit);
}
.about-close-menu {
  width: 5%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-icon12 {
  fill: #D9D9D9;
  width: 100%;
  height: 100%;
}
.about-links2 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.about-link13 {
  color: var(--dl-color-gray-white);
  font-size: 3.5rem;
  font-style: normal;
  font-family: Exo 2;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.about-link14 {
  color: var(--dl-color-gray-white);
  font-size: 3.5rem;
  font-style: normal;
  font-family: Exo 2;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.about-link15 {
  color: var(--dl-color-gray-white);
  font-size: 3.5rem;
  font-style: normal;
  font-family: Exo 2;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.about-text11 {
  color: var(--dl-color-gray-white);
  font-size: 3.5rem;
  font-style: normal;
  font-family: Exo 2;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.about-navlink3 {
  color: var(--dl-color-gray-white);
  font-size: 3.5rem;
  font-style: normal;
  font-family: Exo 2;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.about-link16 {
  display: contents;
}
.about-image2 {
  width: auto;
  height: 15%;
  align-self: center;
  object-fit: cover;
  text-decoration: none;
}
.about-icon-group1 {
  height: 5%;
}
.about-icon14 {
  fill: #D9D9D9;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.about-icon16 {
  fill: #D9D9D9;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.about-icon18 {
  fill: #D9D9D9;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  text-decoration: none;
}
.about-text12 {
  color: var(--dl-color-gray-white);
  width: 413px;
  font-size: 4rem;
  font-style: normal;
  text-align: left;
  font-weight: 900;
  margin-left: var(--dl-space-space-sixunits);
}
.about-container2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 5rem;
  padding-left: 18rem;
  padding-right: 18rem;
  flex-direction: column;
  padding-bottom: 5rem;
  background-color: #262832;
}
.about-image3 {
  width: var(--dl-size-size-large);
  margin: var(--dl-space-space-twounits);
  object-fit: cover;
}
.about-text13 {
  color: var(--dl-color-gray-white);
  margin: 1rem;
  font-size: 1.5rem;
  text-align: center;
}
.about-text14 {
  color: var(--dl-color-gray-white);
  margin: 1rem;
  font-size: 1.5rem;
  text-align: center;
}
.about-container3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  background-image: radial-gradient(circle at center, rgb(38, 40, 50) 0.00%,rgb(0, 0, 0) 98.00%);
}
.about-text15 {
  color: var(--dl-color-gray-white);
  margin: var(--dl-space-space-sixunits);
  font-size: 98px;
}
.about-container4 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: auto;
  height: 982px;
  margin: var(--dl-space-space-fourunits);
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.about-container5 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/bg1-1500w.png");
}
.about-text16 {
  color: var(--dl-color-gray-500);
  font-size: 2.5em;
}
.about-container6 {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-image4 {
  width: auto;
  height: var(--dl-size-size-large);
  object-fit: cover;
}
.about-image5 {
  width: auto;
  height: var(--dl-size-size-large);
  object-fit: cover;
}
.about-footer {
  width: 100%;
  display: flex;
  max-width: auto;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  background-size: cover;
  justify-content: space-between;
  background-image: url("/bg21-200h.png");
}
.about-container7 {
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.about-navlink4 {
  display: contents;
}
.about-image6 {
  height: 2rem;
  margin-bottom: var(--dl-space-space-twounits);
  text-decoration: none;
}
.about-nav2 {
  flex: 0 0 auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: row;
}
.about-link20 {
  color: var(--dl-color-gray-white);
  margin-left: 0px;
  text-decoration: none;
}
.about-link21 {
  color: var(--dl-color-gray-white);
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.about-link22 {
  color: var(--dl-color-gray-white);
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.about-text17 {
  color: var(--dl-color-gray-white);
  display: none;
  margin-left: var(--dl-space-space-unit);
}
.about-text18 {
  color: var(--dl-color-gray-white);
  display: none;
  margin-left: var(--dl-space-space-unit);
}
.about-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.about-container8 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.about-text19 {
  color: var(--dl-color-gray-white);
}
.about-icon-group2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.about-link23 {
  display: contents;
}
.about-icon20 {
  fill: #D9D9D9;
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.about-link24 {
  display: contents;
}
.about-icon22 {
  fill: #D9D9D9;
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.about-link25 {
  display: contents;
}
.about-icon24 {
  fill: #D9D9D9;
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  text-decoration: none;
}
@media(max-width: 991px) {
  .about-container4 {
    flex-direction: column;
  }
}
@media(max-width: 767px) {
  .about-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .about-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .about-desktop-menu {
    display: none;
  }
  .about-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .about-link13 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-link14 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-link15 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-text11 {
    display: none;
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-navlink3 {
    display: none;
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .about-image6 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .about-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .about-container8 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .about-text19 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .about-banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .about-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .about-mobile-menu {
    padding: 16px;
  }
  .about-footer {
    padding: var(--dl-space-space-unit);
  }
  .about-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .about-container8 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .about-text19 {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
