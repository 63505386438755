.home-container10 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  scroll-snap-type: y mandatory;
}
.home-hero {
  width: 100%;
  height: 982px;
  display: none;
  position: relative;
  min-height: 80vh;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-start;
  background-color: #D9D9D9;
  background-image: url("/dreamshaper_v7_website_design_for_a_smartphone_app_with_grey_b_0-1500h.png");
  background-repeat: no-repeat;
  scroll-snap-align: start;
  background-position: top left;
}
.home-navbar-interactive1 {
  width: 100%;
  display: flex;
  align-self: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: 196px;
  padding-right: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: flex-start;
}
.home-testimonial {
  width: 100%;
  height: auto;
  display: flex;
  background-size: cover;
  justify-content: center;
  background-image: url("/card5-1500w.png");
}
.home-container118 {
  gap: var(--dl-space-space-sixunits);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-container119 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text1122 {
  color: var(--dl-color-gray-white);
  font-size: 3rem;
}
.home-text1124 {
  color: var(--dl-color-gray-white);
}
.home-text1125 {
  color: var(--dl-color-gray-white);
  font-size: 1.15rem;
  text-align: center;
}
.home-container120 {
  gap: var(--dl-space-space-sixunits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  align-self: center;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}
.home-container121 {
  flex: 1;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: auto;
  display: none;
  grid-gap: var(--dl-space-space-twounits);
  grid-template-columns: 1fr 1fr 1fr;
}
.home-link10 {
  display: contents;
}
.home-image1 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  text-decoration: none;
}
.home-desktop-menu1 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  color: var(--dl-color-gray-black);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}
.home-links1 {
  gap: 2;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-start;
}
.home-text100 {
  color: var(--dl-color-gray-black);
  font-size: 16px;
  font-style: normal;
  text-align: left;
  font-family: Exo 2;
  font-weight: 600;
}
.home-link11 {
  color: var(--dl-color-gray-black);
  font-size: 16px;
  font-style: normal;
  text-align: left;
  font-family: Exo 2;
  font-weight: 600;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-link12 {
  color: var(--dl-color-gray-black);
  font-size: 16px;
  font-style: normal;
  text-align: left;
  font-family: Exo 2;
  font-weight: 600;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-text101 {
  color: var(--dl-color-gray-black);
  font-size: 16px;
  font-style: normal;
  text-align: left;
  font-family: Exo 2;
  font-weight: 600;
  margin-left: var(--dl-space-space-twounits);
}
.home-text102 {
  color: var(--dl-color-gray-black);
  font-size: 16px;
  font-style: normal;
  text-align: left;
  font-family: Exo 2;
  font-weight: 600;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-burger-menu1 {
  display: none;
}
.home-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-mobile-menu1 {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #262832;
}
.home-nav1 {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-top1 {
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-logo1 {
  width: 5%;
  height: auto;
  margin: var(--dl-space-space-unit);
}
.home-close-menu1 {
  width: 5%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon12 {
  fill: #D9D9D9;
  width: 100%;
  height: 100%;
}
.home-links2 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text103 {
  color: var(--dl-color-gray-white);
  font-size: 3.5rem;
  font-style: normal;
  text-align: center;
  font-family: Exo 2;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text104 {
  color: var(--dl-color-gray-white);
  font-size: 3.5rem;
  font-style: normal;
  text-align: center;
  font-family: Exo 2;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text105 {
  color: var(--dl-color-gray-white);
  font-size: 3.5rem;
  font-style: normal;
  text-align: center;
  font-family: Exo 2;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text106 {
  color: var(--dl-color-gray-white);
  font-size: 3.5rem;
  font-style: normal;
  text-align: center;
  font-family: Exo 2;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text107 {
  color: var(--dl-color-gray-white);
  font-size: 3.5rem;
  font-style: normal;
  text-align: center;
  font-family: Exo 2;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.home-image2 {
  width: auto;
  height: 15%;
  align-self: center;
  object-fit: cover;
}
.home-icon-group1 {
  height: 5%;
}
.home-icon14 {
  fill: #D9D9D9;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon16 {
  fill: #D9D9D9;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon18 {
  fill: #D9D9D9;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}
.home-text108 {
  width: 400px;
  font-size: 36px;
  align-self: flex-start;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  text-align: left;
  font-family: Exo 2;
  font-weight: 700;
  margin-left: 196px;
  text-transform: uppercase;
}
.home-text109 {
  width: 350px;
  font-size: 18px;
  align-self: flex-start;
  margin-top: var(--dl-space-space-threeunits);
  margin-left: 196px;
}
.home-image3 {
  width: var(--dl-size-size-xxlarge);
  margin-top: var(--dl-space-space-twounits);
  object-fit: cover;
  margin-left: 180px;
}
.home-container11 {
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: var(--dl-size-size-small);
  display: flex;
  position: absolute;
  align-self: center;
  margin-top: auto;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  border-radius: var(--dl-radius-radius-round);
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.home-link13 {
  color: transparent;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  padding: 0px;
  align-self: center;
  border-color: #ffffff;
  border-style: dashed;
  border-width: 4px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.21);
}
.home-icon20 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.home-hero2 {
  width: 100%;
  height: 982px;
  display: flex;
  position: relative;
  min-height: 80vh;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-start;
  background-color: #D9D9D9;
  background-image: url("/bg3-1500h.png");
  background-repeat: no-repeat;
  scroll-snap-align: start;
  background-position: top left;
}
.home-hero3 {
  width: 100%;
  height: 982px;
  display: flex;
  position: relative;
  min-height: 80vh;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-start;
  background-color: #D9D9D9;
  background-image: url("/bg3-1500h.png");
  background-repeat: no-repeat;
  scroll-snap-align: start;
  background-position: top left;
}
.home-navbar-interactive2 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sixunits);
  padding-right: var(--dl-space-space-sixunits);
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: space-between;
}
.home-navlink1 {
  display: contents;
}
.home-image4 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  text-decoration: none;
}
.home-desktop-menu2 {
  flex: 1;
  display: flex;
  margin-left: var(--dl-space-space-threeunits);
  justify-content: flex-start;
}
.home-links3 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-navlink2 {
  color: #1266bf;
  font-style: normal;
  font-family: Exo 2;
  font-weight: 600;
  text-decoration: none;
}
.home-link14 {
  color: #1266bf;
  font-style: normal;
  font-family: Exo 2;
  font-weight: 600;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-link15 {
  color: #1266bf;
  font-style: normal;
  font-family: Exo 2;
  font-weight: 600;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-text114 {
  color: #1266bf;
  display: none;
  font-style: normal;
  font-family: Exo 2;
  font-weight: 600;
  margin-left: var(--dl-space-space-twounits);
}
.home-navlink3 {
  color: #1266bf;
  font-style: normal;
  font-family: Exo 2;
  font-weight: 600;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-burger-menu2 {
  display: none;
}
.home-icon22 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-mobile-menu2 {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #262832;
}
.home-nav2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-top2 {
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-logo2 {
  width: 5%;
  height: auto;
  margin: var(--dl-space-space-unit);
}
.home-close-menu2 {
  width: 5%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon24 {
  fill: #D9D9D9;
  width: 100%;
  height: 100%;
}
.home-links4 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text115 {
  color: var(--dl-color-gray-white);
  font-size: 3.5rem;
  font-style: normal;
  font-family: Exo 2;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link16 {
  color: var(--dl-color-gray-white);
  font-size: 3.5rem;
  font-style: normal;
  font-family: Exo 2;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link116 {
  color: var(--dl-color-gray-white);
  font-size: 3.5rem;
  font-style: normal;
  font-family: Exo 2;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link17 {
  color: var(--dl-color-gray-white);
  font-size: 3.5rem;
  font-style: normal;
  font-family: Exo 2;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-text116 {
  color: var(--dl-color-gray-white);
  font-size: 3.5rem;
  font-style: normal;
  font-family: Exo 2;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.home-navlink4 {
  color: var(--dl-color-gray-white);
  font-size: 3.5rem;
  font-style: normal;
  font-family: Exo 2;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link18 {
  display: contents;
}
.home-image5 {
  width: auto;
  height: 15%;
  align-self: center;
  object-fit: cover;
  text-decoration: none;
}
.home-icon-group2 {
  height: 5%;
}
.home-icon26 {
  fill: #D9D9D9;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-icon28 {
  fill: #D9D9D9;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-icon30 {
  fill: #D9D9D9;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  text-decoration: none;
}
.home-container12 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-sixunits);
  padding-right: var(--dl-space-space-sixunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: center;
}
.home-container13 {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: flex-start;
}
.home-text117 {
  color: #1266bf;
  width: 100%;
  font-size: 36px;
  align-self: flex-start;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  text-align: left;
  font-family: Exo 2;
  font-weight: 700;
  margin-left: 0px;
  text-transform: uppercase;
}
.home-text118 {
  color: rgb(18, 102, 191);
  width: 100%;
  font-size: 18px;
  align-self: flex-start;
  margin-top: var(--dl-space-space-threeunits);
  margin-left: 0px;
}
.home-text123 {
  font-weight: 700;
}
.home-link22 {
  display: contents;
}
.home-image6 {
  width: var(--dl-size-size-xxlarge);
  margin-top: var(--dl-space-space-twounits);
  object-fit: cover;
  margin-left: 0px;
  text-decoration: none;
}
.home-lottie {
  flex: initial;
  width: 100%;
  height: 655px;
  margin-left: var(--dl-space-space-twounits);
}
.home-container14 {
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: var(--dl-size-size-small);
  display: none;
  position: absolute;
  align-self: center;
  margin-top: auto;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  border-radius: var(--dl-radius-radius-round);
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.home-link23 {
  color: transparent;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  padding: 0px;
  align-self: center;
  border-color: #ffffff;
  border-style: dashed;
  border-width: 4px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.21);
}
.home-icon32 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.home-features {
  width: 100%;
  height: 982px;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: auto;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-image: radial-gradient(circle at center, rgb(38, 40, 50) 0.00%,rgb(0, 0, 0) 98.00%);
  scroll-snap-align: start;
}
.home-heading {
  color: rgb(255, 255, 255);
  font-size: 3rem;
  margin-top: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-text127 {
  color: rgb(255, 255, 255);
  width: 70%;
  font-size: 1.15rem;
  margin-top: 0px;
  text-align: center;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-container15 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  margin-top: 0px;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  margin-right: var(--dl-space-space-twounits);
  margin-bottom: 0px;
  justify-content: center;
}
.home-lottie-node {
  width: 100%;
  height: 100%;
  align-self: center;
}
.home-container16 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  margin-left: 0px;
  flex-direction: column;
  justify-content: center;
}
.home-pricing {
  flex: initial;
  width: 100%;
  height: 982px;
  display: flex;
  position: relative;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-image: url("/leonardo_diffusion_beautiful_website_pricing_page_light_blue_d_0-1300h.png");
  background-repeat: repeat-y;
  scroll-snap-align: start;
  background-position: center;
  background-attachment: fixed;
}
.home-image7 {
  flex: 1;
  left: -78px;
  width: 504px;
  bottom: -34px;
  height: 611px;
  z-index: 2;
  position: absolute;
  object-fit: cover;
}
.home-image8 {
  top: 0px;
  right: 0px;
  width: 195px;
  height: 292px;
  z-index: 2;
  position: absolute;
  object-fit: cover;
}
.home-container17 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  padding-left: var(--dl-space-space-sixunits);
  padding-right: var(--dl-space-space-sixunits);
  flex-direction: row;
  justify-content: flex-start;
}
.home-container18 {
  width: auto;
  height: 650px;
  display: flex;
  padding: var(--dl-space-space-sixunits);
  max-width: 600px;
  align-self: center;
  box-shadow: -15px 35px 70px -20px rgba(0, 0, 0, 0.43);
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 24px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  background-size: cover,auto;
  justify-content: center;
  background-image: linear-gradient(135deg, rgb(224, 240, 244) 0.00%,rgb(103, 187, 233) 100.00%);
  background-repeat: top left;
  background-position: center,top left;
}
.home-container18:hover {
  transform: scale(1.02);
}
.home-text130 {
  color: #054aa0;
  font-size: 4em;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.home-container19 {
  gap: var(--dl-space-space-threeunits);
  flex: 2;
  width: auto;
  display: flex;
  align-items: center;
  margin-left: 0px;
  margin-right: 0px;
  flex-direction: row;
  justify-content: center;
}
.home-container20 {
  flex: 1;
  width: 45%;
  height: 650px;
  display: flex;
  max-width: 450px;
  align-self: center;
  box-shadow: -15px 35px 70px -20px rgba(0, 0, 0, 0.43);
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  margin-right: 0px;
  padding-left: 0px;
  border-radius: 24px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: 0px;
  background-size: cover;
  justify-content: space-between;
  background-image: url("/card1-700h.png");
}
.home-container20:hover {
  transform: scale(1.02);
}
.home-container21 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text131 {
  color: #054aa0;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
}
.home-text132 {
  color: #054aa0;
  margin: var(--dl-space-space-twounits);
  font-size: 8rem;
  font-style: normal;
  font-weight: 700;
}
.home-container22 {
  width: 100%;
  height: 50%;
  display: none;
  padding: var(--dl-space-space-threeunits);
  align-items: center;
  border-radius: 24px;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url("/card4-500w.png");
}
.home-text133 {
  color: #d7fbff;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text134 {
  font-weight: 700;
}
.home-text136 {
  color: #d7fbff;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text137 {
  font-weight: 700;
}
.home-text139 {
  color: #d7fbff;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text140 {
  font-weight: 700;
}
.home-text141 {
  color: #d7fbff;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text142 {
  font-weight: 700;
}
.home-button1 {
  color: #000000;
  margin-top: auto;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: #ffffff;
}
.home-container23 {
  flex: 1;
  color: var(--dl-color-gray-white);
  width: 45%;
  height: 650px;
  display: flex;
  max-width: 450px;
  align-self: center;
  box-shadow: -15px 35px 70px -20px rgba(0, 0, 0, 0.43);
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  margin-right: 0px;
  padding-left: 0px;
  border-radius: 24px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: 0px;
  background-size: cover;
  justify-content: space-between;
  background-image: url("/card2-500w.png");
}
.home-container23:hover {
  transform: scale(1.02);
}
.home-container24 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text144 {
  color: #054aa0;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
}
.home-text145 {
  color: #054aa0;
  margin: var(--dl-space-space-twounits);
  font-size: 8rem;
  font-style: normal;
  font-weight: 700;
}
.home-container25 {
  width: 100%;
  height: 50%;
  display: none;
  padding: var(--dl-space-space-threeunits);
  align-items: center;
  border-radius: 24px;
  flex-direction: column;
  justify-content: center;
  background-color: #e7eef6;
}
.home-text146 {
  color: #054aa0;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text147 {
  font-weight: 700;
}
.home-text149 {
  color: #054aa0;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text150 {
  font-weight: 700;
}
.home-text152 {
  color: #054aa0;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text153 {
  font-weight: 700;
}
.home-text155 {
  color: #054aa0;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text156 {
  font-weight: 700;
}
.home-button2 {
  color: #ffffff;
  margin-top: auto;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: #054aa0;
}
.home-pricing2 {
  width: 100%;
  height: 982px;
  display: none;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(180deg, rgb(36, 232, 246) 2.00%,rgb(32, 111, 171) 100.00%);
  scroll-snap-align: start;
}
.home-container26 {
  width: 100%;
  height: 654px;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  justify-content: center;
}
.home-container27 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: column;
}
.home-text157 {
  color: #ffffff;
}
.home-text158 {
  color: #ffffff;
  margin-top: var(--dl-space-space-twounits);
  text-align: left;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-container28 {
  flex: 2;
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.home-container29 {
  flex: 1;
  height: 450px;
  display: flex;
  max-width: 450px;
  box-shadow: 5px 5px 10px 0px #000000;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  background-color: #262832;
}
.home-container29:hover {
  transform: scale(1.02);
}
.home-text162 {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  font-weight: 600;
}
.home-text163 {
  color: rgb(255, 255, 255);
  margin: var(--dl-space-space-twounits);
  font-size: 4rem;
  font-weight: 200;
}
.home-text164 {
  color: #ffffff;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text165 {
  font-weight: 700;
}
.home-text167 {
  color: #ffffff;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text168 {
  font-weight: 700;
}
.home-text170 {
  color: #ffffff;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text171 {
  font-weight: 700;
}
.home-text173 {
  color: #ffffff;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text174 {
  font-weight: 700;
}
.home-button3 {
  color: #000000;
  margin-top: auto;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: #ffffff;
}
.home-container30 {
  flex: 1;
  color: var(--dl-color-gray-white);
  height: 450px;
  display: flex;
  max-width: 450px;
  box-shadow: 5px 5px 10px 0px #000000;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  background-color: #FAF3DA;
}
.home-container30:hover {
  transform: scale(1.02);
}
.home-text175 {
  color: rgb(0, 0, 0);
  font-size: 1.5rem;
  font-weight: 600;
}
.home-text176 {
  color: rgb(0, 0, 0);
  margin: var(--dl-space-space-twounits);
  font-size: 4rem;
  font-weight: 200;
}
.home-text177 {
  color: #000000;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text178 {
  font-weight: 700;
}
.home-text180 {
  color: #000000;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text181 {
  font-weight: 700;
}
.home-text183 {
  color: #000000;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text184 {
  font-weight: 700;
}
.home-text186 {
  color: #000000;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text187 {
  font-weight: 700;
}
.home-button4 {
  color: #ffffff;
  margin-top: auto;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: #262832;
}
.home-pricing3 {
  width: 100%;
  height: 982px;
  display: none;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(180deg, rgb(254, 204, 123) 0.00%,rgb(250, 171, 96) 40.00%);
  scroll-snap-align: start;
}
.home-container31 {
  width: 100%;
  height: 654px;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  justify-content: center;
}
.home-container32 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: column;
}
.home-text188 {
  color: #ffffff;
}
.home-text189 {
  color: #ffffff;
  margin-top: var(--dl-space-space-twounits);
  text-align: left;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-container33 {
  flex: 2;
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.home-container34 {
  flex: 1;
  height: 450px;
  display: flex;
  max-width: 450px;
  box-shadow: 5px 5px 10px 0px #000000;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  background-color: #262832;
}
.home-container34:hover {
  transform: scale(1.02);
}
.home-text193 {
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 600;
}
.home-text194 {
  color: #ffffff;
  margin: var(--dl-space-space-twounits);
  font-size: 4rem;
  font-weight: 200;
}
.home-text195 {
  color: #ffffff;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text196 {
  font-weight: 700;
}
.home-text198 {
  color: #ffffff;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text199 {
  font-weight: 700;
}
.home-text201 {
  color: #ffffff;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text202 {
  font-weight: 700;
}
.home-text204 {
  color: #ffffff;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text205 {
  font-weight: 700;
}
.home-button5 {
  color: var(--dl-color-gray-white);
  margin-top: auto;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-gray-black);
}
.home-container35 {
  flex: 1;
  color: var(--dl-color-gray-white);
  height: 450px;
  display: flex;
  max-width: 450px;
  box-shadow: 5px 5px 10px 0px #000000;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  background-color: #2192C6;
}
.home-container35:hover {
  transform: scale(1.02);
}
.home-text206 {
  font-size: 1.5rem;
  font-weight: 600;
}
.home-text207 {
  margin: var(--dl-space-space-twounits);
  font-size: 4rem;
  font-weight: 200;
}
.home-text208 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text209 {
  font-weight: 700;
}
.home-text211 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text212 {
  font-weight: 700;
}
.home-text214 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text215 {
  font-weight: 700;
}
.home-text217 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text218 {
  font-weight: 700;
}
.home-button6 {
  margin-top: auto;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
}
.home-footer {
  width: 100%;
  display: flex;
  max-width: auto;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  background-size: cover;
  justify-content: space-between;
  background-image: url("/bg21-200h.png");
}
.home-container36 {
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-navlink5 {
  display: contents;
}
.home-image9 {
  height: 2rem;
  margin-bottom: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-nav3 {
  flex: 0 0 auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: row;
}
.home-text219 {
  color: var(--dl-color-gray-white);
  margin-left: 0px;
  text-decoration: none;
}
.home-link24 {
  color: var(--dl-color-gray-white);
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link124 {
  color: var(--dl-color-gray-white);
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link25 {
  color: var(--dl-color-gray-white);
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-text220 {
  color: var(--dl-color-gray-white);
  display: none;
  margin-left: var(--dl-space-space-unit);
}
.home-text221 {
  color: var(--dl-color-gray-white);
  display: none;
  margin-left: var(--dl-space-space-unit);
}
.home-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-container37 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-text222 {
  color: var(--dl-color-gray-white);
}
.home-icon-group3 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-link26 {
  display: contents;
}
.home-icon34 {
  fill: #D9D9D9;
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-link27 {
  display: contents;
}
.home-icon36 {
  fill: #D9D9D9;
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-link28 {
  display: contents;
}
.home-icon38 {
  fill: #D9D9D9;
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  text-decoration: none;
}
@media(max-width: 991px) {
  .home-navbar-interactive1 {
    padding-left: var(--dl-space-space-threeunits);
  }
  .home-text108 {
    margin-left: var(--dl-space-space-threeunits);
  }
  .home-text109 {
    margin-left: var(--dl-space-space-threeunits);
  }
  .home-image3 {
    margin-left: var(--dl-space-space-threeunits);
  }
  .home-hero2 {
    height: auto;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-container12 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text117 {
    margin-left: var(--dl-space-space-threeunits);
  }
  .home-text118 {
    margin-left: var(--dl-space-space-threeunits);
  }
  .home-image6 {
    margin-left: var(--dl-space-space-threeunits);
  }
  .home-lottie {
    height: 557px;
  }
  .home-features {
    height: auto;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-heading {
    text-align: center;
  }
  .home-text127 {
    text-align: center;
  }
  .home-container15 {
    height: auto;
    flex-direction: column;
  }
  .home-lottie-node {
    width: 100%;
    height: 494px;
    padding-bottom: 0px;
  }
  .home-container16 {
    width: 100%;
    height: auto;
  }
  .home-pricing {
    height: auto;
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-image7 {
    left: -79px;
    bottom: -21px;
  }
  .home-container17 {
    flex-direction: column;
  }
  .home-container18 {
    width: 100%;
    height: auto;
    margin: var(--dl-space-space-twounits);
  }
  .home-container19 {
    width: 100%;
  }
  .home-container26 {
    align-items: center;
    flex-direction: column;
  }
  .home-container27 {
    align-items: center;
  }
  .home-text158 {
    text-align: center;
  }
  .home-container28 {
    width: 100%;
  }
  .home-container31 {
    align-items: center;
    flex-direction: column;
  }
  .home-container32 {
    align-items: center;
  }
  .home-text189 {
    text-align: center;
  }
  .home-container33 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .home-hero {
    align-items: center;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-navbar-interactive1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-desktop-menu1 {
    display: none;
  }
  .home-burger-menu1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-text103 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text104 {
    color: var(--dl-color-gray-white);
    font-size: 3.5rem;
    font-style: normal;
    font-weight: 700;
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text105 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text106 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text107 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-hero2 {
    height: auto;
    align-items: center;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-navbar-interactive2 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-desktop-menu2 {
    display: none;
  }
  .home-burger-menu2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-text115 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-link16 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-link17 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text116 {
    display: none;
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-navlink4 {
    display: none;
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-container12 {
    gap: var(--dl-space-space-twounits);
    flex: 1;
    height: auto;
    flex-direction: column;
  }
  .home-container13 {
    width: 100%;
    align-items: center;
    padding-right: 0px;
  }
  .home-text117 {
    text-align: center;
    margin-left: 0px;
  }
  .home-text118 {
    text-align: center;
    margin-left: 0px;
  }
  .home-image6 {
    margin-left: 0px;
  }
  .home-lottie {
    height: 498px;
    margin-left: 0px;
  }
  .home-features {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    justify-content: flex-start;
  }
  .home-heading {
    text-align: center;
  }
  .home-container15 {
    flex: initial;
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-pricing {
    height: 1240px;
    padding-top: var(--dl-space-space-sixunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-sixunits);
    justify-content: flex-start;
  }
  .home-image7 {
    left: 4px;
    width: 60%;
    bottom: -6px;
  }
  .home-image8 {
    width: 25%;
  }
  .home-container17 {
    height: 100%;
    padding-left: 20%;
    padding-right: 20%;
    flex-direction: column;
  }
  .home-container18 {
    width: 100%;
    height: 20%;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-container19 {
    flex: inital;
    height: 70%;
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-container20 {
    gap: var(--dl-space-space-threeunits);
    flex: initial;
    width: 100%;
    height: 45%;
    margin-right: 0px;
    margin-bottom: 0px;
    justify-content: center;
  }
  .home-container21 {
    height: auto;
    justify-content: flex-start;
  }
  .home-container22 {
    display: none;
  }
  .home-container23 {
    gap: var(--dl-space-space-threeunits);
    width: 100%;
    height: 45%;
    justify-content: center;
  }
  .home-container25 {
    display: none;
  }
  .home-container26 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-container28 {
    flex-direction: column;
  }
  .home-container29 {
    width: 100%;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-container30 {
    width: 100%;
  }
  .home-container31 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-container33 {
    flex-direction: column;
  }
  .home-container34 {
    width: 100%;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-container35 {
    width: 100%;
  }
  .home-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-image9 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-container37 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-text222 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .home-hero {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-navbar-interactive1 {
    padding: var(--dl-space-space-unit);
  }
  .home-mobile-menu1 {
    padding: 16px;
  }
  .home-hero2 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-navbar-interactive2 {
    padding: var(--dl-space-space-unit);
  }
  .home-mobile-menu2 {
    padding: 16px;
  }
  .home-container12 {
    flex-wrap: wrap;
  }
  .home-features {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-image7 {
    left: -27px;
    width: 400px;
    bottom: -6px;
  }
  .home-container26 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container31 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-footer {
    padding: var(--dl-space-space-unit);
  }
  .home-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-container37 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-text222 {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
